var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activityList" }, [
    _c(
      "div",
      { staticStyle: { background: "#fff", padding: "20px" } },
      [
        _vm.storeList && _vm.storeList.length > 0
          ? _c(
              "div",
              { staticClass: "store_content" },
              [
                _c("span", [_vm._v("请先选择店铺: ")]),
                _c(
                  "el-select",
                  {
                    attrs: { size: "mini", placeholder: "请先选择店铺" },
                    model: {
                      value: _vm.storeId,
                      callback: function ($$v) {
                        _vm.storeId = $$v
                      },
                      expression: "storeId",
                    },
                  },
                  _vm._l(_vm.storeList, function (item) {
                    return _c("el-option", {
                      key: item.storeId,
                      attrs: { label: item.storeName, value: item.storeId },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "search-panel",
          {
            attrs: { flex: "", "show-btn": "" },
            on: { getList: _vm.getListChange },
          },
          [
            _c(
              "div",
              [
                _c("p", [_vm._v("活动类型：")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "mini",
                      placeholder: "请选择活动类型",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchParams.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "type", $$v)
                      },
                      expression: "searchParams.type",
                    },
                  },
                  _vm._l(_vm.typeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("活动名称：")]),
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入活动名称",
                    size: "mini",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchParams.title,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.searchParams,
                        "title",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "searchParams.title",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "header-cell-style": {
                background: "#D9DDE1",
                color: "#0D0202",
                "font-weight": 400,
              },
              "element-loading-text": "拼命加载中",
            },
          },
          [
            _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
            _c("el-table-column", {
              attrs: { prop: "title", label: "活动名称", "min-width": "210" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "goods_message" }, [
                        _c("div", { staticClass: "left" }, [
                          _c("img", { attrs: { src: scope.row.imageIds } }),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(scope.row.title) + " "),
                          ]),
                          _c("div", { staticClass: "id" }, [
                            _vm._v(" " + _vm._s(scope.row.content) + " "),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "projectCodeName", label: "活动平台" },
            }),
            _c("el-table-column", {
              attrs: { prop: "type", label: "活动类型", width: "110" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(" " + _vm._s(_vm.typeList[scope.row.type]) + " "),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "报名时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dayFormat(scope.row.startTime)) +
                          "--" +
                          _vm._s(_vm.dayFormat(scope.row.endTime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "活动时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.dayFormat(scope.row.showStarttime)) +
                          "--" +
                          _vm._s(_vm.dayFormat(scope.row.showEndtime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "状态", width: "90" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.status == 0 ? "已上架" : "已下架") +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "110",
                fixed: "right",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            size: "mini",
                            disabled: scope.row.status == 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 查看详情 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.pagination.page,
            "page-sizes": [10, 20, 30, 50],
            "page-size": _vm.pagination.page_count,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.pagination.item_total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }