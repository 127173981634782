<template>
  <div class="activityList ">
    <div style="background: #fff; padding: 20px">
      <div
        v-if="storeList && storeList.length > 0"
        class="store_content"
      >
        <span>请先选择店铺: </span>
        <el-select
          v-model="storeId"
          size="mini"
          placeholder="请先选择店铺"
        >
          <el-option
            v-for="item in storeList"
            :key="item.storeId"
            :label="item.storeName"
            :value="item.storeId"
          />
        </el-select>
      </div>
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <p>活动类型：</p>
          <el-select
            v-model="searchParams.type"
            size="mini"
            placeholder="请选择活动类型"
            clearable
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <p>活动名称：</p>
          <el-input
            v-model.trim="searchParams.title"
            placeholder="请输入活动名称"
            size="mini"
            clearable
          />
        </div>
      </search-panel>

      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          prop="id"
          label="ID"
        />
        <el-table-column
          prop="title"
          label="活动名称"
          min-width="210"
        >
          <template slot-scope="scope">
            <div class="goods_message">
              <div class="left">
                <img :src="scope.row.imageIds" />
              </div>
              <div class="right">
                <div class="title">
                  {{ scope.row.title }}
                </div>
                <div class="id">
                  {{ scope.row.content }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="projectCodeName"
          label="活动平台"
        />
        <el-table-column
          prop="type"
          label="活动类型"
          width="110"
        >
          <template slot-scope="scope">
            {{ typeList[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column
          label="报名时间"
          width="160"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.startTime) }}--{{ dayFormat(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="活动时间"
          width="160"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.showStarttime) }}--{{ dayFormat(scope.row.showEndtime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          width="90"
        >
          <template slot-scope="scope">
            {{ scope.row.status == 0 ? '已上架' : '已下架' }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="110"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              :disabled="scope.row.status == 1"
              @click="viewDetail(scope.row)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
/**
 * 活动报名列表页面
 * 用途：用于供应商参加活动报名
 */
import dayjs from 'dayjs';
import { typeOptions, typeList } from '../config.js';

export default {
  name: 'activity-list',
  data() {
    return {
      storeList: JSON.parse(localStorage.getItem('userInfo')).storeList,
      storeId: JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId,
      typeOptions,
      typeList,
      searchParams: {
        title: '',
        type: null,
      },
      tableData: [],
      loading: false,

      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {

    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val;
      this.pagination.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    getListChange() {
      this.pagination.page = 1;
      this.getList();
    },

    getList() { // index为页面当前页数
      let _this = this;
      _this.loading = true;
      _this.$axios({
        method: 'get',
        url: _this.$api.tradeProm.activity_list,
        params: {
          ...this.searchParams,
          currPage: _this.pagination.page,
          pageSize: _this.pagination.page_count,
        },
      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          _this.tableData = res.data.records;
        } else {
          _this.tableData = [];
          _this.pagination.item_total = 0;
        }
        _this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    },

    viewDetail(row) {
      this.$router.push({
        path: '/nb/marketingTools/activity/detail',
        query: {
          id: row.id, title: row.title, storeId: this.storeId, activityType: row.type,
        },
      });
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },

  },
};
</script>
<style lang="scss" scoped>
.activityList {
  .store_content {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
      color: red;
    }
  }
}

</style>

<style lang="scss">
 .goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }

</style>
